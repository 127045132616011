<template>
  <div>
    <v-container fluid class="pa-0 bg-grey">
      <v-toolbar>
        <TicketFilter>
          <v-slide-item class="mt-5"  v-if="logged_user && logged_user.group !== 'Prestataire'">
            <v-btn color="primary" @click="switchDrawer">Nouveau ticket</v-btn>
          </v-slide-item>
        </TicketFilter>
      </v-toolbar>

      <v-row no-gutters class="ma-0 pa-0" style="border-top: #a59999 1px solid">
        <v-col order-md="1" order="2" md="5" cols="12" id="ticket-list">
          <TicketList :loading-tickets="loading_tickets"/>
        </v-col>
        <v-col order-md="2" order="1" md="7" cols="12" id="item-view">
          <router-view @edit-ticket="editTicket()"></router-view>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import NewTicketSidebar from "@/components/tickets/NewTicketSidebar";
import config from "@/config";
import TicketList from "@/components/tickets/TicketList";
import TicketFilter from "@/components/tickets/TicketFilter";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";

console.log(config);


export default {
  name: "Tickets",
  components: {TicketFilter, TicketList},
  mixins: [AuthMixin, DrawerMixin],
  async mounted() {
    await this.$store.dispatch("tickets/fetchTickets");
    this.loading_tickets = true;
  },
  data: function () {
    return {
      dialog: false,
      operation: "create",
      ticketToUpdate: {},
      loading_tickets: false,
    }
  },
  methods: {
    changeState(state) {
      let updatedTicket = {...this.selected_ticket, state};
      updatedTicket.asset = updatedTicket.asset !== null ? updatedTicket.asset.id : null;
      updatedTicket.created_by = 1;
      updatedTicket.intervenant = 1;
      this.$store.dispatch("tickets/putTicket", updatedTicket);
    },
    setTicket(ticket) {
      this.$store.commit("tickets/setSelectedTicket", ticket);
    },
    editTicket() {
      let properties = {
        operation: "update",
        ticketToUpdate: this.selected_ticket,
        key: this.selected_ticket.id,
      };
      this.openDrawerDialog(NewTicketSidebar, properties);
    },
    switchDrawer() {
      let properties = {
        operation: this.operation,
        ticketToUpdate: this.ticketToUpdate,
        key: -1
      };
      console.log(properties);
      this.openDrawerDialog(NewTicketSidebar, properties);
    },
    closeDrawer() {
      /*
      this.operation = "create";
      this.ticketToUpdate = null;
      this.dialog = false;*/
    },
    pieceJointeLink(file) {
      return config.BASE_URL + file;
    },
    sites_select() {
      return [
          {value: "", name: "Tous les sites"},
        ...this.$store.getters["sites/allSites"]
            .map(site => ({"name": this.site_name(site), "value": this.site_name(site)}))
      ];
    },
  },
  computed: {
    selected_ticket() {
      return this.$store.getters["tickets/getSelectedTicket"];
    },
  },

}
</script>

