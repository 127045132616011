<template>

  <v-card elevation="0" flat style="max-height: 100vh" class="overflow-y-auto">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title v-if="operation === 'create'">Nouveau ticket</v-toolbar-title>
      <v-toolbar-title v-if="operation === 'update'">Mettre à jour le ticket</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form class="px-3">
        <v-select label="Priorité"
                  :items="priority"
                  v-model="ticketPriority"
                  item-text="label"
                  item-value="value"></v-select>
        <v-text-field label="Demandé par" v-model="ticketDemandePar"></v-text-field>
        <v-select v-if="operation === 'update'" label="Statut" :items="state"
                  item-text="label"
                  item-value="value"
                  v-model="ticketState"></v-select>
        <v-text-field label="Objet" v-model="ticketObjet"></v-text-field>
        <v-textarea v-model="ticketDescription" label="Description"></v-textarea>
        <v-row justify="space-between">
          <span class="mt-7 mx-1"><v-icon>mdi-filter</v-icon>Filtrer par</span>
          <div>
            <SiteTreeSelector v-model="assets_site_tree"/>
          </div>
        </v-row>
        <v-autocomplete label="Équipement"
                  item-text="name"
                  item-value="id"
                  :items="filtered_assets" v-model="ticketEquipement">
        </v-autocomplete>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" v-if="operation === 'create' " text @click="createTicket">
        Créer le ticket
      </v-btn>
      <v-btn color="primary" v-if="operation === 'update'" text @click="updateTicket">
        Mettre à jour
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import TicketMixin from "@/mixins/tickets/TicketMixin";
import SiteTreeSelector from "@/views/SiteTreeSelector";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";

const priority_select = [
  {"value": "low", "label": "Basse"},
  {"value": "medium", "label": "Moyen"},
  {"value": "high", "label": "Élevée"}
];

let status_select = [
  {"value": "closed", "label": "Cloturé"},
  {"value": "waiting", "label": "En cours d'assignation"},
  {"value": "finished", "label": "Réalisée"},
  {"value": "processed", "label": "Traité"}
];
export default {
  name: 'NewTicketSidebar',
  components: {SiteTreeSelector},
  mixins: [TicketMixin, DrawerMixin],
  props: {
    operation: String,
    ticketToUpdate: Object,
  },
  mounted() {
    if (this.$store.getters['assets/allAssets'].length === 0) {
      this.$store.dispatch("assets/fetchAssets");
    }
  },
  data: function () {
    let logged_user = this.$store.getters["users/getLoggedUser"];
    return {
      ticketObjet: this.operation === "update" ? this.ticketToUpdate.objet : "",
      ticketDescription: this.operation === "update" ? this.ticketToUpdate.description : "",
      ticketEquipement: this.operation === "update" ? this.ticketToUpdate.asset?.id : null,
      ticketState: this.operation === "update" ? this.ticketToUpdate.state : "",
      ticketDemandePar: this.operation === "update" ? this.ticketToUpdate.created_by.fullname : logged_user !== null ? logged_user.fullname : "Admin",
      ticketPriority: this.operation === "update" ? this.ticketToUpdate.priority : "low",
      priority: priority_select,
      state: status_select,
      assets_site_tree: [],
    };
  },
  methods: {
    resetFields() {
      this.ticketObjet = "";
      this.ticketDescription = "";
      this.ticketEquipement = null;
      this.ticketDemandePar = this.logged_user !== null ? this.logged_user.fullname : "Admin";
      this.ticketPriority = "low";
    }
    ,
    async createTicket() {
      let ticket = {
        objet: this.ticketObjet,
        description: this.ticketDescription,
        priority: this.ticketPriority,
        asset: this.ticketEquipement,
        created_by: this.logged_user?.id,
        intervenant: 1,
        state: "unassigned",
      };
      await this.$store.dispatch("tickets/postTicket", ticket);
      // if(this.selected_ticket?.id) await this.handleDirectAssignation(this.ticketEquipement);
      // this.$emit("close-drawer");
      this.closeDrawerDialog();
      this.resetFields();
    }
    ,
    async updateTicket() {
      let ticket = {
        objet: this.ticketObjet,
        description: this.ticketDescription,
        priority: this.ticketPriority,
        asset: this.ticketEquipement,
        id: this.ticketToUpdate.id,
        created_by: this.ticketToUpdate.created_by?.id,
        state: this.ticketState,
      };
      await this.$store.dispatch("tickets/patchTicket", ticket);
      this.closeDrawerDialog();
      this.resetFields();
    },
    async handleDirectAssignation(asset_id) {
      if(asset_id == null){
        return;
      }
      await this.$store.dispatch("assets/fetchAssetById", asset_id);
      let asset = this.$store.getters["assets/getSelectedAsset"];
      if(asset.intervenant == null) {
        return;
      }
      await this.$store.dispatch("profile/getProfileById", asset.intervenant.id);
      let userProfile = this.$store.getters["profile/getSelectedProfile"];
      let direct_ticket = userProfile.profile?.prestataire?.direct_ticket;
      if(direct_ticket === false) {
        return;
      }
      let intervention = {
          "date_intervention": null,
          "date_fin_intervention": null,
          "validation": false,
          "cout_achat": null,
          "ticket": this.selected_ticket.id,
          "prestataire": userProfile.id,
          "categorie": userProfile.profile.metiers.length > 0 ? userProfile.profile.metiers[0].id : null
        }
      await this.$store.dispatch("interventions/postIntervention", intervention);
      await this.$store.dispatch("tickets/patchTicket", {"id": this.selected_ticket.id, "state": "waiting"});
      await this.$store.dispatch("messages/setAnnounce", {"type": "success", "message": "Ticket créé et directement assigné au prestataire correspondant."});
    }
  },
  computed: {
    logged_user() {
      return this.$store.getters["users/getLoggedUser"];
    },
    assets() {
      return this.$store.getters['assets/allAssets'].map(asset => ({fullname: `${asset.name} #${asset.balise}`, ...asset}));
    },
    filtered_assets() {
      let that = this;
      return this.$store.getters["assets/allAssets"].filter(function (asset) {
        return that.assets_site_tree.length > 0 && that.assets_site_tree.indexOf("") === -1
            ? that.assets_site_tree.indexOf(asset?.site?.id) !== -1 : true;
      })
    },
    selected_ticket() {
      return this.$store.getters["tickets/getSelectedTicket"];
    }
  },
  destroyed() {
    this.assets_site_tree = [];
  }
}
</script>